<template>
  <div>
    <!-- SECTION -->
    <section class="section section-secondary jpadding jpadding-5">
      <div class="jcard jcard-main jcard-nohover">
        <!-- TITLE -->
        <div class="d-flex mb-3">
          <h3><i class="fal fa-newspaper"></i> News</h3>
          <div class="ml-auto">
            <button class="btn btn-outline-primary" @click="showNewServiceDialog">
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
        <!-- Search -->
        <SearchAndFilters class="d-flex mb-3" :searchList=newsList></SearchAndFilters>
        <div class="jblock jblock-grid">
          <!-- LIST -->
          <div v-for="(news, index) in newsList" :key="news.id" class="jcard-medium">
            <div class="d-flex align-items-center">
              <h5>{{ news.title }}</h5>
              <!-- <button
                @click="showEditServiceDialog(index)"
                class="ml-auto jbtn-text jbtn-grey jbtn-circle-hover"
              >
                <i class="fa fa-ellipsis-v"></i>
              </button> -->
              <b-dropdown right variant="link" toggle-class="jbtn jbtn-icon jbtn-icon-link" class="ml-auto" no-caret>
                <template #button-content>
                  <i class="fa fa-ellipsis-v"></i>
                </template>
                <b-dropdown-item @click="openEditNews(index)"><i class="fal fa-pencil mr-2"></i> Edit Article
                </b-dropdown-item>
                <b-dropdown-item @click="showEditServiceDialog(index)"><i class="fal fa-check mr-2"></i> Publish Article
                </b-dropdown-item>
                <b-dropdown-item @click="showEditServiceDialog(index)"><i class="fal fa-times mr-2"></i> Unpublish
                  Article</b-dropdown-item>
                <b-dropdown-item @click="deleteService(index)"><i class="fal fa-trash mr-2"></i> Delete Article
                </b-dropdown-item>
              </b-dropdown>
            </div>

            <!-- <div class="jdropdown">
              <button
                @click="dropdown1 = !dropdown1"
                class="jbtn-text jbtn-grey jbtn-circle-hover "
              >
                <i class="fa fa-ellipsis-v"></i>
              </button>

              <ul v-bind:class="{ hidden: !dropdown1 }" class="jdropdown-list">
                <li><i class="fal fa-pencil"></i> Edit</li>
                <li><i class="fal fa-trash"></i> Delete</li>
              </ul>
            </div> -->

            <hr />
            <div class="form-row">
              <div class="col-md">
                <p><small>News ID</small><br />{{ news.id.substring(0, 8) }}</p>
              </div>
              <div class="col-md">
                <p><small>Category</small><br />{{ news.category }}</p>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md">
                <p>
                  <small>Status</small><br />
                  <span class="badge badge-pill" v-bind:class="{
                    'badge-success': news.published,
                    'badge-danger': !news.published
                  }">{{ news.published ? "PUBLISHED" : "UNPUBLISHED" }}</span>
                </p>
              </div>
              <div class="col-md">
                <p>
                  <small>Published Date</small><br />{{ news.dateString }}
                </p>
              </div>
            </div>
            <hr />
            <p>
              <small>{{ news.text }}</small>
            </p>
            <hr />
            <p>
              <button v-if="news.hasPdf" type="button" class="jbtn-text" @click="openPdf(news.pdfUrl)"><i
                  class="fa fa-file-pdf"></i></button>
              <button v-else type="button" class="jbtn-grey" disabled><i class="fa fa-file-pdf"></i></button>

            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- ----- new/edit news modal --------------------------------- -->
    <b-modal :hide-header="true" :hide-footer="true" id="new-service-modal" ref="new-service-modal" centered
      title="BootstrapVue">
      <div class="jdialog-top">
        <div class="close-container">
          <h3>
            <strong>{{ editMode ? "Update" : "Create" }} News Article</strong>
          </h3>
          <span @click="$bvModal.hide('new-service-modal')" class="ml-auto jclose"><i class="fa fa-times "></i></span>
        </div>
        <p v-if="editMode">
          <small>Update existing news article.</small>
        </p>
        <p v-else><small>Add a news article.</small></p>
      </div>
      <div class="jdialog-main">
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label for="name"> <i class="fal fa-tag"></i> Name</label>
              <input type="text" v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editData.name) && bluredName
              }" v-on:blur="bluredName = true" v-model="editData.name" />
              <div class="invalid-feedback">
                Please enter a valid name for the service.
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md">
            <div class="form-group">
              <label for="price"><i class="fal fa-euro-sign"></i> Price</label>
              <input type="text" v-bind:class="{
                'form-control': true,
                'is-invalid': !validNonTxtField(editData.price) && bluredPrice
              }" v-on:blur="bluredPrice = true" v-model="editData.price" />
              <div class="invalid-feedback">
                Please enter a valid price.
              </div>
            </div>
          </div>

          <div class="col-md">
            <div class="form-group">
              <label for="status"><i class="fal fa-exclamation-circle"></i> Status</label>
              <b-form-select v-model="editData.active"
                :options="$C.getFormattedBoolOptionsArray($C.STATUS.SERVICE_INDEX)"></b-form-select>
              <div class="invalid-feedback">
                Please select a valid status.
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="text"><i class="fal fa-comment-alt"></i> Text</label>
          <textarea type="text" v-bind:class="{
            'form-control': true,
            'is-invalid': !validInput(editData.text) && bluredText
          }" v-on:blur="bluredText = true" v-model="editData.text" />
          <div class="invalid-feedback">
            Please enter a short description text.
          </div>
        </div>

        <!-- alert -->
        <b-alert v-if="showInputError" show variant="danger"><i class="fad fa-exclamation-circle"></i>
          {{ validationMsg }}
        </b-alert>
      </div>

      <!-- dialog bottom -->
      <div class="jdialog-bottom with-cancel">
        <button @click="$bvModal.hide('new-service-modal')" class="jbtn jbtn-sm jbtn-red">
          <i class="fa fa-times"></i> Cancel
        </button>
        <button @click="updateService" class="jbtn jbtn-sm">
          <i class="fa fa-check"></i> {{ editMode ? "Update" : "Create" }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import toast from "@/assets/js/toast";
import SearchAndFilters from "@/components/general/SearchAndFilters";
import C from "@/const";
import moment from "moment";
import firebase from "@/firebase/firebaseInit";
const db = firebase.db;


export default {
  name: "News",
  components: {
    SearchAndFilters,
  },
  data() {
    return {
      newsList: [],

      isLoading: false,
      isSaving: false,

      //form error alert
      showInputError: false,
      validationMsg: "",

      //edit news info form
      editMode: false,
      valid: false,
      bluredName: false,
      bluredText: false,
      bluredPrice: false,

      serviceId: "",
      editData: {
        name: "",
        active: true,
        text: "",
        price: 0
      }
    };
  },
  methods: {
    //load all services list
    allServices: function () {
      db.collection(C.COLLECTION.NEWS).onSnapshot(querySnapshot => {
        this.newsList.splice(0);
        querySnapshot.forEach(doc => {
          const docdata = doc.data();
          docdata.id = doc.id;
          docdata.statusName = docdata.published ? "published" : "unpublished";
          docdata.dateString = moment(C.getDateFromTimestamp(docdata.publishedOn)).format("DD-MM-YYYY");
          this.newsList.push(docdata);
        });
      });
    },
    //open pdf file in new tab
    openPdf(url) {
      console.log('Opening PDF...');
      window.open(url, '_blank') //to open in new tab
    },
    // show/init create service dialog
    showNewServiceDialog() {
      this.editMode = false;
      this.validationMsg = "";
      // reset valid fields
      this.bluredName = false;
      this.bluredText = false;
      this.bluredPrice = false;
      // reset data
      this.editData.name = "";
      this.editData.active = true;
      this.editData.text = "";
      this.editData.price = 0;
      // open dialog
      this.$refs["new-service-modal"].show();
    },
    //
    openEditNews(index) {
      this.$router.push({
        name: "CreateNews",
        params: { id: this.newsList[index].id }
      });
    },
    // show/init edit service dialog
    showEditServiceDialog(index) {
      this.editMode = true;
      this.validationMsg = "";
      // load data
      this.serviceId = this.newsList[index].id;
      this.editData.name = this.newsList[index].name;
      this.editData.active = this.newsList[index].active;
      this.editData.text = this.newsList[index].text;
      this.editData.price = this.newsList[index].price;
      // open dialog
      this.$refs["new-service-modal"].show();
    },
    // save catalogue service to db
    updateService() {
      if (!this.validate()) return;
      var docRef;
      if (this.editMode) docRef = db.collection(C.COLLECTION.NEWS).doc(this.serviceId);
      else docRef = db.collection(C.COLLECTION.NEWS).doc();
      docRef
        .set(this.editData, { merge: true })
        .then(() => {
          this.$refs["new-service-modal"].hide();
          if (this.editMode) toast.success("Catalogue service edited.");
          else toast.success("New service added to the catalogue.");
        })
        .catch(error => {
          if (this.editMode) toast.error("Couldn't edit catalogue service. " + error.message);
          else toast.error("Couldn't create new service. " + error.message);
        });
    },
    deleteService(index) {
      if (confirm("Do you really want to delete this service?")) {
        var docRef = db.collection(C.COLLECTION.NEWS).doc(this.newsList[index].id);
        docRef
          .delete()
          .then(() => {
            console.log("Service successfully deleted!");
          })
          .catch(error => {
            console.error("Error removing service: ", error);
          });
      }
    },
    validate() {
      //Check first name
      this.bluredName = true;
      if (!this.validInput(this.editData.name)) {
        this.valid = false;
        return false;
      }
      this.bluredPrice = true;
      if (!this.validNonTxtField(this.editData.price)) {
        this.valid = false;
        return false;
      }
      this.bluredText = true;
      if (!this.validInput(this.editData.text)) {
        this.valid = false;
        return false;
      }

      this.valid = true;
      return true;
    },
    validInput(input) {
      return input.trim().length > 0;
    },
    validNonTxtField(input) {
      return input != null && input != "";
    }
  },
  mounted() {
    this.allServices();
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/css/platform.scss";
</style>
